<!--  -->
<template>
  <div class="stu">
    <el-card class="box-card">
      <div>
        共计学生{{ echartsData.member_count }}名,涉及学校{{
          echartsData.schoole_count
        }}所,平均年龄{{ echartsData.avg_age }}岁,本网站注册平均时长{{
          echartsData.avg_register
        }}
      </div>
      <div>
        <el-row :gutter="21" style="margin-top: 20px">
          <el-col :span="6">
            <diy-echarts
              class="border-base"
              v-bind="schoole_distribute"
            ></diy-echarts>
          </el-col>
          <el-col :span="6">
            <diy-echarts
              class="border-base"
              v-bind="age_distribute"
            ></diy-echarts>
          </el-col>
          <el-col :span="6">
            <diy-echarts
              class="border-base"
              v-bind="gender_distribute"
            ></diy-echarts>
          </el-col>
          <el-col :span="6">
            <diy-echarts
              class="border-base"
              v-bind="regist_distribute"
            ></diy-echarts>
          </el-col>
        </el-row>
      </div>

      <div class="table" style="margin-top: 20px" v-loading="loading">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :row-key="(row) => row.id"
          @sort-change="sortChange"
          height="650px"
        >
          <el-table-column
            label="学校-姓名"
            prop="school_name"
            width="160"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.show_name }}</span></template
            >
          </el-table-column>
          <el-table-column
            label="当前所在班"
            prop="course_name"
            align="center"
            width="280"
          >
            <template slot-scope="scope">
              <div v-for="(v, i) in scope.row.current_course" :key="i">
                {{ v.course_name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="上次所在班"
            prop="course_name"
            align="center"
            width="280"
          >
            <template slot-scope="scope">{{
              scope.row.history_course
                ? scope.row.history_course.course_name
                : ""
            }}</template>
          </el-table-column>
          <el-table-column
            label="出生年月"
            prop="birth"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.birth }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="性别"
            prop="sex"
            align="center"
            width="80"
            sortable="custom"
          >
            <template slot-scope="scope">{{ scope.row.sex }}</template>
          </el-table-column>
          <el-table-column
            label="年级"
            prop="grade_name"
            align="center"
            width="80"
            sortable="custom"
          >
            <template slot-scope="scope">{{ scope.row.grade_name }}</template>
          </el-table-column>
          <el-table-column
            label="电话号码"
            align="center"
            prop="phone"
            sortable="custom"
          >
            <template slot-scope="scope">{{ scope.row.phone }}</template>
          </el-table-column>
          <el-table-column
            label="注册时间"
            prop="create_time"
            width="110"
            align="center"
            sortable="custom"
          >
            <template slot-scope="scope">{{ scope.row.create_date }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination" style="margin-top: 20px">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :total="total"
          @current-change="currentChange"
          @size-change="handleSizeChange"
          :page-size="pageSize"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import DiyEcharts from "@/components/com/el/diyEcharts";
var dayjs = require("dayjs");
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    DiyEcharts,
  },
  props: {
    group_id: {
      type: Number,
      default: 0,
    },
    timevalue: {
      type: Array,
      default: () => [],
    },
    template_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    //这里存放数据
    return {
      loading: false,
      page: 1,
      pageSize: 50,
      total: 0,
      tableData: [],
      totalTips: {},
      schoole_distribute: {
        name: "学校分布",
        data: [[]],
      },
      age_distribute: {
        name: "年龄分布",
        data: [[]],
      },
      gender_distribute: {
        name: "性别分布",
        data: [[]],
      },
      regist_distribute: {
        name: "注册时间分布",
        data: [[]],
      },
      echartsData: {
        avg_age: 0, //平均年龄
        member_count: 0, //学生人数
        avg_register: 0, //平均注册时长
        schoole_count: 0, //学校人数
      },
      sort: "",
      start_time: "",
      end_time: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    timevalue: {
      handler(value) {
        console.log(value, "value");
        if (value) {
          this.start_time = value[0];
          this.end_time = value[1];
        } else {
          this.start_time = "";
          this.end_time = "";
        }
        this.getStuList();
        this.echartsTotal();
      },
    },
    template_id: {
      handler(value) {
        console.log(value, "value");
        if (value) {
          this.template_id = value;
        } else {
          this.template_id = "";
        }
        this.getStuList();
        this.echartsTotal();
      },
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.timevalue, "timevalue");
    if (this.timevalue && this.timevalue.length>0) {
      this.start_time = this.timevalue[0];
      this.end_time = this.timevalue[1];
    } else {
      this.start_time = "";
      this.end_time = "";
    }
    this.echartsTotal();
    this.getStuList();

  },
  //方法集合
  methods: {
    // echarts统计
    echartsTotal() {
      this.$http
        .post("/xapi/user.group/studentMessageState", {
          group_id: Number(this.group_id),
          start_time: this.start_time,
          end_time: this.end_time,
          template_id: Number(this.template_id),
        })
        .then((res) => {
          this.echartsData.avg_age = res.data.avg_age;
          this.echartsData.avg_register = res.data.avg_register;
          this.echartsData.member_count = res.data.member_count;
          this.echartsData.schoole_count = res.data.schoole_count;
          this.schoole_distribute.data = [res.data.schoole_distribute];
          this.age_distribute.data = [res.data.age_distribute];
          this.gender_distribute.data = [res.data.gender_distribute];
          this.regist_distribute.data = [res.data.regist_distribute];
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 获取学生列表
    getStuList() {
      // page: 1,
      // pageSize: 50,
      // total: 0,
      this.loading = true;

      this.$http
        .get(
          `/xapi/user.group/studentMessageList?page=` +
            this.page +
            "&page_size=" +
            this.pageSize +
            "&sort=" +
            this.sort +
            "&group_id=" +
            Number(this.group_id) +
            "&template_id=" +
            Number(this.template_id) +
            "&start_time=" +
            this.start_time +
            "&end_time=" +
            this.end_time
        )
        .then((res) => {
          console.log(res, "liebbbbb");
          this.loading = false;

          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 排序
    sortChange(column) {
      console.log(column, "column");
      const orderData =
        column.order == "descending"
          ? "desc"
          : column.order == "ascending"
          ? "asc"
          : null;
      console.log(orderData, "orderData");
      this.sort = column.prop + "-" + orderData;
      this.getStuList();
    },
    // 分页
    currentChange(e) {
      this.page = e;
      this.getStuList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getStuList();
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
.stu {
  .box-card {
    margin-top: 20px;
    ::v-deep {
      .border-base {
        text-align: center;
        border-radius: 20px;
        padding: 10px;
        border: 1px solid #ccc;
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>