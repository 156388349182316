<!--  -->
<template>
  <div class="stu">
    <el-card class="box-card">
      <div>
        <el-row :gutter="21" style="margin-top: 20px">
          <el-col :span="24">
            <diy-echarts
              class="border-base"
              v-bind="video_distribute"
            ></diy-echarts>
          </el-col>
        </el-row>
        <el-row :gutter="21" style="margin-top: 20px">
          <el-col :span="12">
            <diy-echarts
              class="border-base"
              v-bind="question_distribute"
            ></diy-echarts>
          </el-col>
          <el-col :span="12">
            <diy-echarts
              class="border-base"
              v-bind="question_time_distribute"
            ></diy-echarts>
          </el-col>
        </el-row>

        <el-row :gutter="21" style="margin-top: 20px">
          <el-col :span="12">
            <diy-echarts
              class="border-base"
              v-bind="target_distribute"
            ></diy-echarts>
          </el-col>
          <el-col :span="12">
            <diy-echarts
              class="border-base"
              v-bind="ac_distribute"
            ></diy-echarts>
          </el-col>
        </el-row>
      </div>

      <div class="table" style="margin-top: 20px" v-loading="loading">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :row-key="(row) => row.id"
          height="650px"
        >
          <el-table-column label="学校-姓名" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.show_name }}</span></template
            >
          </el-table-column>
          <el-table-column label="提交题目总数" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.t_num }}</span>
            </template>
          </el-table-column>
          <el-table-column label="非编程题正确数" align="center">
            <template slot-scope="scope">{{ scope.row.usw_num }}</template>
          </el-table-column>
          <el-table-column label="全AC编程题数" align="center">
            <template slot-scope="scope">{{ scope.row.swac_num }}</template>
          </el-table-column>
          <el-table-column label="观看视频次数" align="center">
            <template slot-scope="scope">{{
              scope.row.study_duration
            }}</template>
          </el-table-column>
          <el-table-column label="参加班课数" align="center">
            <template slot-scope="scope">{{ scope.row.course_num }}</template>
          </el-table-column>
          <el-table-column label="参加考试次数" align="center">
            <template slot-scope="scope">{{ scope.row.t2_num }}</template>
          </el-table-column>
          <el-table-column label="参加自修课程数" align="center">
            <template slot-scope="scope">{{ scope.row.fc_num }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination" style="margin-top: 20px">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          :total="total"
          @current-change="currentChange"
          @size-change="handleSizeChange"
          :page-size="pageSize"
          :current-page="page"
        >
        </el-pagination>
      </div>
      <div style="font-size: 12px; color: gray; margin-top: 10px">
        <!-- <p>提交作业率 = 提交作业次数/作业次数</p>
        <p>提交题目率 = 提交题目数/作业内总题目数</p> -->
        <!-- <p>编程题目全AC率 = 提交结果全AC题目数/作业内编程题目总数</p> -->

        <p>注:AC的含义是ACCEPT,即正确</p>
      </div>
    </el-card>
  </div>
</template>
  
  <script>
import DiyEcharts from "@/components/com/el/diyEcharts";
var dayjs = require("dayjs");
export default {
  props: {
    course_id: {
      type: Number,
      default: 0,
    },
    timevalue: {
      type: Array,
      default: ()=>[],
    },
    template_id: {
      type: Number,
      default: 0,
    },
  },
  //import引入的组件需要注入到对象中才能使用
  components: {
    DiyEcharts,
  },
  data() {
    //这里存放数据
    return {
      loading: false,
      page: 1,
      pageSize: 50,
      total: 0,
      tableData: [],
      video_distribute: {
        name: "学生观看课程视频次数、时长",
        type: "zhu",
        options: { labels: [] },
        data: [
          [
            // { value: 10, name: "2023-02" },
            // { value: 24, name: "2023-03" },
            // { value: 19, name: "2023-04" },
            // { value: 56, name: "2023-05" },
            // { value: 15, name: "2023-05" },
          ],
        ],
      },
      question_distribute: {
        name: "学生题目提交次数",
        type: "zhu",
        options: { labels: [] },
        data: [
          [
            // { value: 42, name: "2023-02" },
            // { value: 24, name: "2023-03" },
            // { value: 54, name: "2023-04" },
            // { value: 35, name: "2023-05" },
          ],
        ],
      },
      question_time_distribute: {
        name: "学生做题时间分布",
        type: "zhu",
        options: { labels: [] },
        data: [
          [
            // { value: 0, name: "1点" },
            // { value: 1, name: "2点" },
            // { value: 5, name: "3点" },
            // { value: 4, name: "4点" },
            // { value: 7, name: "5点" },
            // { value: 4, name: "6点" },
            // { value: 4, name: "7点" },
            // { value: 4, name: "8点" },
          ],
        ],
      },
      target_distribute: {
        name: "全部作业题目标签",
        data: [
          [
            // { value: 1, name: "语言基础" },
            // { value: 2, name: "函数" },
            // { value: 3, name: "结构化程序设计" },
            // { value: 4, name: "队列" },
            // { value: 5, name: "基本运算" },
          ],
        ],
      },
      ac_distribute: {
        name: "提交结果全AC题目标签",
        data: [
          [
            // { value: 1, name: "语言基础" },
            // { value: 2, name: "函数" },
            // { value: 3, name: "结构化程序设计" },
            // { value: 4, name: "队列" },
            // { value: 5, name: "基本运算" },
          ],
        ],
      },
      start_time:"",
      end_time:"",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    timevalue:{
      handler(value) {
        console.log(value,"value")
        if(value){
          this.start_time=value[0]
          this.end_time=value[1]
        }else{
          this.start_time=""
          this.end_time=""
        }
        this.getList();
        this.echartsTotal();
      }
    },
    template_id:{
      handler(value) {
        console.log(value,"value")
        if(value){
          this.template_id=value
        }else{
          this.template_id=""
        }
        this.getList();
        this.echartsTotal();
      }
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.timevalue, "timevalue");
    if (this.timevalue && this.timevalue.length>0) {
      this.start_time = this.timevalue[0];
      this.end_time = this.timevalue[1];
    } else {
      this.start_time = "";
      this.end_time = "";
    }
    if (this.course_id) {
      this.historyTotal();
      this.getHistoryList();
    } else {
      this.echartsTotal();
      this.getList();
    }
  },
  //方法集合
  methods: {
    // echarts统计
    echartsTotal() {
      this.$http
        .post("/xapi/user.group/studyStatistic",
        {
          start_time:this.start_time,
          end_time:this.end_time,
          template_id:Number(this.template_id),
        })
        .then((res) => {
          console.log(res, "学情统计");
          this.video_distribute.data = [res.data.video_distribute];
          this.question_distribute.data = [res.data.question_distribute];
          this.question_time_distribute.data = [
            res.data.question_time_distribute,
          ];
          this.target_distribute.data = [res.data.target_distribute];
          this.ac_distribute.data = [res.data.ac_distribute];
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 学情历史
    historyTotal() {
      this.$http
        .post("/xapi/user.group/courseAllHisPieChart", {
          course_id: this.course_id,
        })
        .then((res) => {
          console.log(res, "历史学情统计");
          this.video_distribute.data = [res.data.video_distribute];
          this.question_distribute.data = [res.data.question_distribute];
          this.question_time_distribute.data = [
            res.data.question_time_distribute,
          ];
          this.target_distribute.data = [res.data.target_distribute];
          this.ac_distribute.data = [res.data.ac_distribute];
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 统计的学情下学生列表
    getList() {
      this.loading = true;
      this.$http
        .get(
          "/xapi/user.group/studyStatisticList?page=" +
            this.page +
            "&page_size=" +
            this.pageSize
            +"&template_id=" + Number(this.template_id)
            +"&start_time=" +this.start_time
            +"&end_time=" + this.end_time
        )
        .then((res) => {
          console.log(res, "liebbbbb");
          this.loading = false;

          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 学情历史的学生列表
    getHistoryList() {
      this.loading = true;
      this.$http
        .get(
          "/xapi/user.group/allStudyStatisticList?page=" +
            this.page +
            "&page_size=" +
            this.pageSize +
            "&course_id=" +
            this.course_id
        )
        .then((res) => {
          console.log(res, "liebbbbb");
          this.loading = false;

          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    // 分页
    currentChange(e) {
      this.page = e;
      if (this.course_id) {
        this.getHistoryList();
      } else {
        this.getList();
      }
    },
    handleSizeChange(e) {
      this.pageSize = e;
      if (this.course_id) {
        this.getHistoryList();
      } else {
        this.getList();
      }
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
  <style  lang='less' scoped>
.stu {
  .box-card {
    margin-top: 20px;
    ::v-deep {
      .border-base {
        text-align: center;
        border-radius: 20px;
        padding: 10px;
        border: 1px solid #ccc;
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>